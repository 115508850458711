import React, { useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import strings from "../../languages/strings";
import { Link } from "react-router-dom";

import { useMutation, useQuery } from "react-query";
import { makeStyles } from "@mui/styles";
import { apiGet, apiPut } from "../../api/api";
import Loader from "../../components/utils/Loader";

import Header from "../../components/collections/Header";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import GoBack from "../../components/utils/buttons/GoBack";
import { useCurrentUserStore } from "../../stores/useCurrentUserStore";
import EditUsers from "../../components/collections/EditUsers";

const EditCollection = (props: any) => {
  const { user } = useCurrentUserStore.getState();
  const classes = useStyles();
  const {
    match: { params },
  } = props;

  const basicProps = {
    variant: "outlined" as "outlined",
    sx: {
      marginBottom: 2,
    },
  };

  const [loadedProject, setLoadedProject] = useState<any>({});

  const { isLoading } = useQuery(
    "artObject" + params.artObjectId,
    async () => {
      return apiGet(`/projects/${params.projectId}`);
    },
    {
      onSuccess: (data) => {
        setLoadedProject(data);
      },
      refetchOnMount: "always",
    }
  );

  const { mutate, isLoading: isSaving } = useMutation(
    async (values: any) => {
      const { name, maker, makerRkdLink } = values;
      const project = {
        projectId: params.projectId,
        name,
        maker,
        makerRkdLink,
      };

      await apiPut(`/projects/${params.projectId}`, project);

      return {};
    },
    {
      onSuccess: () => {
        props.history.push(`/app/collections/${params.projectId}/artObjects`);
      },
    }
  );

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = "Verplicht";
    }

    return errors;
  };
  const handleSubmit: any = (values: any) => {
    mutate(values);
  };

  return (
    <Box className={classes.root}>
      <Loader open={isLoading || isSaving} />
      <Header projectId={params.projectId} />
      <Breadcrumbs aria-label="breadcrumb" className={"breadcrumbs"}>
        <Link style={{ color: "black" }} to="/app/collections">
          {strings.collections}
        </Link>
        <Link
          style={{ color: "black" }}
          to={`/app/collections/${params.projectId}`}
        >
          {loadedProject != null ? loadedProject.name : "..."}
        </Link>
        <Link
          style={{ color: "black" }}
          to={`/app/collections/${params.projectId}`}
        >
          Wijzigen
        </Link>
      </Breadcrumbs>
      <Box className={classes.buttons}>
        <GoBack
          label={"Terug naar collectie"}
          to={`/app/collections/${params.projectId}/artObjects`}
        />
      </Box>
      <Card className={classes.content}>
        <Loader open={isLoading} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant={"h4"} marginBottom={2}>
                Wijzig collectie
              </Typography>

              <Form
                initialValues={loadedProject}
                validate={validate}
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <TextField
                      id={"collection-name"}
                      label={strings.collection.fields.name}
                      name="name"
                      {...basicProps}
                    />
                    <TextField
                      id={"collection-maker"}
                      label={"Standaard vervaardiger"}
                      name="maker"
                      required
                      {...basicProps}
                    />
                    <TextField
                      id={"collection-makerRkdLink"}
                      label={"Standaard vervaardiger RKD link"}
                      name="makerRkdLink"
                      required
                      {...basicProps}
                    />
                    <Button
                      id={"collection-edit-submit"}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                    >
                      Opslaan
                    </Button>
                  </form>
                )}
              />
            </Grid>
            {user && (user.role === "ADMIN" || user.role === "MANAGER") && (
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant={"h4"} marginBottom={2}>
                  Gekoppelde gebruikers
                </Typography>
                <EditUsers projectId={params.projectId} />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

export default EditCollection;
