import React, { useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useCurrentUserStore } from "../../stores/useCurrentUserStore";
import { useMutation, useQuery } from "react-query";
import { fetchProjectUsers } from "../../api/Projects";
import { fetchUsers } from "../../api/Users";
import { apiPost } from "../../api/api";

const EditUsers = ({ projectId }: { projectId: number }) => {
  const { user } = useCurrentUserStore.getState();

  if (!user || user.role != "ADMIN") {
    return <Box></Box>;
  }

  const [projectsUsers, setProjectsUsers] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [newUser, setNewUser] = useState<any>("");

  const { refetch } = useQuery(
    ["project", projectId],
    () => fetchProjectUsers(projectId),
    {
      onSuccess: (data) => {
        setProjectsUsers(data);
      },
      refetchOnMount: "always",
    }
  );

  useQuery(["users"], () => fetchUsers(), {
    onSuccess: (data) => {
      setUsers(
        data.sort((a: any, b: any) => a.username.localeCompare(b.username))
      );
    },
    refetchOnMount: "always",
  });

  const handleAddNewUser = useMutation(
    async () => {
      await apiPost(`/projects/${projectId}/users`, {
        userId: newUser,
      });
    },
    {
      onSuccess: () => {
        setNewUser("");
        refetch();
      },
    }
  );

  return (
    <Box>
      <Box mt={1}>
        {projectsUsers &&
          projectsUsers.map((user: any) => (
            <Typography>{user.username}</Typography>
          ))}
      </Box>
      <Box pt={5}>
        <Typography variant={"h5"} marginBottom={1}>
          Gebruiker toevoegen:
        </Typography>
        <Paper>
          <Select
            name="userId"
            disableUnderline
            fullWidth
            displayEmpty
            value={newUser}
            variant={"standard"}
            onChange={(event) => {
              setNewUser(event.target.value);
            }}
            style={{ padding: 3, paddingLeft: 8 }}
          >
            <MenuItem value={""}>
              <span style={{ color: "#a7a7a7" }}>Selecteer gebruiker...</span>
            </MenuItem>
            {users.map((user: any) => (
              <MenuItem key={"users" + user.id} value={user.id}>
                {user.username}
              </MenuItem>
            ))}
          </Select>
        </Paper>
        <Button
          id={"collection-submit"}
          onClick={() => handleAddNewUser.mutate()}
          variant="contained"
          color={"primary"}
          fullWidth
          style={{ marginTop: 15 }}
        >
          Voeg gebruiker toe
        </Button>
      </Box>
    </Box>
  );
};

export default EditUsers;
