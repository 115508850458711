import React, { useMemo, useState } from "react";

import { useQuery } from "react-query";
import { Grid, Paper } from "@mui/material";
import Table, { DateCell, DateTimeCell } from "../utils/table/Table";
import { fetchUsers } from "../../api/Users";

const UserList = () => {
  const [data, setData] = useState<any>([]);
  const columns = useMemo(
    () => [
      {
        Header: "Created",
        accessor: "createdAt",
        Cell: DateCell,
        sortType: (a: any, b: any) => {
          let a1 = new Date(a).getTime();
          let b1 = new Date(b).getTime();
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        }
      },
      {
        Header: "Username",
        accessor: "username"
      },
      {
        Header: "Totaal kunstwerken",
        accessor: (d: any) =>
          `${d.totalObjects.toLocaleString()} / ${d.limit.toLocaleString()}`
      },
      {
        Header: "Subscription",
        accessor: "subscription"
      },
      {
        Header: "Role",
        accessor: "role"
      },
      {
        Header: "Last login",
        accessor: "lastLogin",
        Cell: DateTimeCell
      }
    ],
    []
  );

  useQuery("users", fetchUsers, {
    onSuccess: data => {
      if (data) {
        setData(data);
      }
    },
    refetchOnMount: "always"
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Table
            columns={columns}
            data={data}
            label={"gebruikers"}
            initialState={{
              sortBy: [
                {
                  id: "lastLogin",
                  desc: true
                }
              ]
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UserList;
